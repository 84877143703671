import {useSelector} from "react-redux";
import {useMemo} from "react";
import styles from '../index.module.scss'
import clsx from "clsx";
import Button from "@/components/common/button"
import { DeliveryTypes, UserFields } from "~/types/cart";
import {daysTextForm} from "~/utils";

export default function ShopsNew({currentType, onBuy, canBuy, isShow, productId}: any) {
    const {delivery} = useSelector((state: any) => state.product)
    const {contactsData} = useSelector((state: any) => state.contacts)
    const {shops} = useSelector((state: any) => state.location)

    const shopsFiltered = useMemo(() => {
        return delivery?.[productId]?.map((item: any) => {

            return item;
        });
    }, [delivery, contactsData])

    const getQty = (qty: any, type: any) => {
        if (type === 'relocation') {
            return '<span style="color:red">Под заказ</span>';
        } else {
            if (qty < 5) {
                return  'мало';
            } else {
                return 'много';
            }
        }
    }

    return isShow && (
        <div>
            <br/>
            Новый блок информации о доставке
            <div className={styles.Table}>
                <span className={clsx(styles.TableFirst, styles.Head)}>Адреса магазинов</span>
                <span className={styles.Head}>Режим работы</span>
                <span className={styles.Head}>Наличие</span>
                <span className={styles.Head}>{currentType === DeliveryTypes.pickup && 'Время самовывоза'}</span>
                <span className={styles.HeadLast}></span>

                {shopsFiltered.map((shop: any) => (
                    <>
                        <span className={styles.TableFirst}>{shops[shop.id].NAME}</span>
                        <span><div className="muted">{shops[shop.id].WORK_TIME}</div></span>
                        <span dangerouslySetInnerHTML={{__html: getQty(shop.availability, shop.availabilityType)}}></span>
                        <span>{daysTextForm(shop.days)}</span>
                        <span className={styles.TableButton}>
                            {canBuy && (
                                <Button label={'Оформить покупку'} type={'outline'} onClick={onBuy}/>
                            )}
                        </span>
                    </>
                ))}
            </div>
        </div>
    )
}