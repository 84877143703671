import {landings} from "~/types/landings";

export function declOfNum(cnt, text_forms, addNum = true) {
    let n = Math.abs(cnt) % 100;
    let n1 = n % 10;
    if (n > 10 && n < 20) { return addNum ? `${cnt} ${text_forms[2]}` : text_forms[2]; }
    if (n1 > 1 && n1 < 5) { return addNum ? `${cnt} ${text_forms[1]}` : text_forms[1]; }
    if (n1 == 1) { return addNum ? `${cnt} ${text_forms[0]}` : text_forms[0]; }
    return addNum ? `${cnt} ${text_forms[2]}` : text_forms[2];
}

export function daysTextForm(days) {
    if (days == 0) {
        return 'сегодня';
    } else if (days == 1) {
        return 'завтра';
    } else if (days == 2) {
        return 'послезавтра';
    } else if (days > 2) {
        return 'через ' + declOfNum(days, ['день', 'дня', 'дней']);
    }
}

export function getFutureDate(days) {
    // Текущая дата
    const currentDate = new Date();

    // Добавляем количество дней
    currentDate.setDate(currentDate.getDate() + days);

    // Форматируем вывод: дата в формате "дд.мм" и день недели
    const formattedDate = currentDate.toLocaleDateString('ru-RU', {
        day: '2-digit',
        month: '2-digit'
    });
    const dayOfWeek = currentDate.toLocaleDateString('ru-RU', {
        weekday: 'long'
    });

    // Возвращаем строку с датой и днем недели
    return `${formattedDate} (${dayOfWeek.charAt(0).toUpperCase() + dayOfWeek.slice(1)})`;
}

export function getMarkUrl (section, mark) {
    if (section.length > 0) section += 'filter/';
    return section + 'marka-' + mark + '/';
}

export function getModelUrl(section, mark, model) {
    if (section.length > 0) section += 'filter/';
    return section + getMarkUrl('', mark) + 'model-' + model + '/';
}

export function getTypeUrl(section, mark, model, type) {
    if (section.length > 0) section += 'filter/';
    return section + getModelUrl('', mark, model) + 'kuzov-' + type + '/';
}

export function getFilterUrl(sectionPageUrl, filter, newParam = {}, pageNum = 1) {
    let filterUrl = {}
    if (newParam.marka) {
        filterUrl = {
            marka: newParam.marka
        }
    } else if (newParam.model) {
        filterUrl = {
            marka: filter.marka,
            model: newParam.model
        }
    } else if (newParam.kuzov) {
        filterUrl = {
            marka: filter.marka,
            model: filter.model,
            kuzov: newParam.kuzov
        }
    } else if (Object.entries(newParam).length > 0) {
        const [key, value] = Object.entries(newParam)[0]
        filterUrl = JSON.parse(JSON.stringify(filter))
        if (filterUrl[key]) {
            if (filterUrl[key].includes(value)) {
                if (filterUrl[key].length === 1) {
                    delete filterUrl[key]
                } else {
                    filterUrl[key] = filterUrl[key].filter(val => val !== value)
                }
            } else {
                filterUrl[key].push(value)
            }
        } else {
            filterUrl[key] = [value]
        }
    } else {
        filterUrl = JSON.parse(JSON.stringify(filter))
    }
    let filterUrlDecoded = ''
    Object.keys(filterUrl).map(paramKey => {
        let param = filterUrl[paramKey]
        if (!param) {
            return
        }
        if (Array.isArray(param)) {
            if (param[0] === 'to' || param[0] === 'from') {
                param = param.join('-')
            } else {
                param = param.join('-or-')
            }
        }
        filterUrlDecoded += `/${paramKey}-${param}`
    })
    if (pageNum > 1) {
        return `${sectionPageUrl}filter${filterUrlDecoded}/?PAGEN_1=${pageNum}`
    }
    return `${sectionPageUrl}filter${filterUrlDecoded}/`
}

export function toQueryString(obj, urlEncode = false) {
    if (!obj) return null;
    const flattenObj = (x, path = []) => {
        const result = [];
        Object.keys(x).forEach((key) => {
            if (!Object.prototype.hasOwnProperty.call(x, key)) return;
            const newPath = path.slice();
            newPath.push(key);
            let vals = [];
            if (x[key] === null) {
                return;
            } else if (typeof x[key] === 'object') {
                vals = flattenObj(x[key], newPath);
            } else {
                vals.push({ path: newPath, val: x[key] });
            }
            vals.forEach((v) => {
                return result.push(v);
            });
        });
        return result;
    };
    let parts = flattenObj(obj);
    parts = parts.map((varInfo) => {
        if (varInfo.path.length === 1) {
            varInfo.path = varInfo.path[0]; // eslint-disable-line no-param-reassign
        } else {
            const first = varInfo.path[0];
            const rest = varInfo.path.slice(1);
            varInfo.path = `${first}[${rest.join('][')}]`; // eslint-disable-line no-param-reassign
        }
        return varInfo;
    });

    const queryString = parts.map((varInfo) => {
        return `${varInfo.path}=${varInfo.val}`;
    }).join('&');
    if (urlEncode) {
        return encodeURIComponent(queryString);
    }
    return queryString;
};

export function getEdostMinDays(edost) {
    let min = null;
    edost.map(item => {
        const itemMin = parseInt(item['day'].split('-')[0])
        if (!min || itemMin < min) {
            min = itemMin
        }
    })
    return min
}

export function setCookie(name,value,days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + ";";
}

export function days(days) {
    const deselection = function (n, arr) {
        return arr[
            (n %= 100, 20 > n && n > 4) ? 2
                :[2,0,1,1,1,2][ (n %= 10, n < 5) ? n : 5]
            ];
    };
    if (days == 0){
        return 'Сегодня';
    } else if (days == 1) {
        return 'Завтра';
    } else if (days == 2) {
        return 'Послезавтра';
    } else if (days > 2) {
        return 'через ' + days + ' ' + deselection(days, ['день', 'дня', 'дней']);
    }
}

export function getDateString(date) {
    const dat = new Date(date);
    return dat.getDay() + '.' + dat.getMonth() + '.' + dat.getFullYear();
}

export function isServer() {
    return typeof window === "undefined"
}


export async function cacheFunctionData(cacheFunction, cacheName, reset = false, storageType = 'session') {
    let storage;
    if (!isServer()) {
        if (storageType === 'session' && typeof sessionStorage === 'object') {
            storage = sessionStorage
        } else if (storageType === 'local' && typeof localStorage === 'object') {
            storage = localStorage
        }
        let cacheData = {}
        if (!reset && storage && cacheName) {
            cacheData = storage.getItem(cacheName)
            if (cacheData) {
                try {
                    cacheData = JSON.parse(cacheData)
                    return cacheData
                } catch(error) {

                }
            }
        }
    }
    let data = await cacheFunction()
    if (!isServer()) {
        if (storage && cacheName) {
            storage.setItem(cacheName, JSON.stringify(data))
        }
    }
    return data
}

export function setTextCity(host, domains, text) {
    if (!text) {
        return text;
    }
    const subDomain = host.split('.')[0]
    if (subDomain === 'msk') {
        return text.replaceAll('#CITY#', 'Москве')
    }
    const city = domains[subDomain]
    text = text.replaceAll('#CITY#', city?.['DEC'] ?? 'Санкт-Петербурге');
    if (subDomain !== 'msk' && subDomain !== 'spb') {
        text = text.replaceAll(' с установкой', '');
    }
    return text
}

export function getEdostMinPriceCorrected(price, productSize, catalogSize = '1000-300-200-15000') {
    const catalogSizeArray = catalogSize.replaceAll('х', '-').replaceAll('x', '-').split('-')
    
    let correctedPrice = price;
    const productSizeSum = productSize.HEIGHT + productSize.LENGTH + productSize.WIDTH;
    const catalogSizeSum = parseInt(catalogSizeArray[0]) + parseInt(catalogSizeArray[1]) + parseInt(catalogSizeArray[2]);
    let sizeCorrect = productSizeSum / catalogSizeSum;

    let correctWeight = true;
    if (sizeCorrect > 1.4) {
        sizeCorrect = 1.4;
        //correctWeight = false;
    }
    if (sizeCorrect < 0.9) {
        sizeCorrect = 0.9;
        //correctWeight = false;
    }
    
    //console.log('productSize', productSize, productSizeSum)
    //console.log('catalogSize', catalogSizeArray, catalogSizeSum, sizeCorrect)

    correctedPrice = correctedPrice * sizeCorrect 

    if (correctWeight) {
        const producWeight = productSize.WEIGHT;
        const defaultWeight = catalogSizeArray[3];
        let weightCorrect = producWeight / defaultWeight;
        if (weightCorrect > 1.4) {
            weightCorrect = 1.4;
        }
        if (weightCorrect < 0.9) {
            weightCorrect = 0.9;
        }

        correctedPrice = correctedPrice * weightCorrect;

        //console.log('producWeight', producWeight, defaultWeight, weightCorrect)
    }


    return parseInt(correctedPrice)
}


export function generateSiteMap(pages, host, isIndex = false) {
    let sitemap = ''
    if (isIndex) {
        sitemap += '<?xml version="1.0" encoding="UTF-8"?><sitemapindex xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">';
    } else {
        sitemap += '<?xml version="1.0" encoding="UTF-8"?><urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">';
    }
    const date = new Date();
    if (Array.isArray(pages)) {
        pages.map(page => {
            if (isIndex) {
                sitemap += `<sitemap><loc>https://${host}${page.LOC}</loc><lastmod>${page.LASTMOD}</lastmod></sitemap>`
            } else {
                sitemap += `<url><loc>https://${host}${page.LOC}</loc><lastmod>${page.LASTMOD}</lastmod></url>`
            }
        })
    }
    if (isIndex) {
        sitemap += '</sitemapindex>';
    } else {
        sitemap += '</urlset>';
    }

    return sitemap;
}

export function formatType(type) {
    if (type) {
        const year = new Date().getFullYear();
        //type = type.replace('(', '').replace(')', '').replace(' по наст.', `-${year}`).replace('- ', `-${year} `)
        type = type.replace(' по наст.', `-${year}`).replace('- ', `-${year} `)
        if (type[type.length - 1] === '-') {
            type += year;
        }
    }
    return type
}


export function checkIsBaltex(host) {
    return host.includes('baltex.ru') || host.includes('localhost_');
}

export function checkIsLanding(host) {
    return landings.includes(host);
}

export function getCookieDomain() {
    let domain = window.location.host;
    if (domain.includes('localhost')) {
        return 'localhost';
    }
    const domainArray = domain.split('.');
    if (domainArray.length > 2) {
        domain = `.${domainArray[1]}.${domainArray[2]}`
    }
    return domain;
}